<template>
  <v-stepper v-model="e1">
    <b-modal
      id="modal-1"
      :title="msgModaleTitle"
      ref="msg-modal"
      ok-only
      @ok="nextClickTemplateList()"
    >
      <p class="my-4">{{ msg }}</p>
    </b-modal>
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1"
        >Generate Templates</v-stepper-step
      >
      <v-divider></v-divider>
      <v-stepper-step :complete="e1 > 2" step="2"
        >Section Mapping</v-stepper-step
      >
      <v-divider></v-divider>
      <v-stepper-step step="3">Update Question Details</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <b-form
          @submit.prevent="onSubmitTemplate"
          @reset.prevent="resetForm"
          v-if="show"
          id="form-stage-1-id"
        >
          <div class="row">
            <div class="col-sm-4">
              <v-select
                id="questionnaire"
                label="Select Questionnaire *"
                v-model="questionnaireSelected"
                :items="questionnaires"
                :options="questionnaires"
                single
                chips
                v-on:change="getRegulations"
              ></v-select>
            </div>
            <div class="col-sm-8">
              <v-select
                id="regln_id"
                label="Select Regulations *"
                v-model="regulationsSelected"
                :items="regulations"
                :options="regulations"
                @change="createSelectedRegList"
                multiple
                chips
                :disabled="tpl_id > 0"
              ></v-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <v-text-field
                id="tpl_name"
                label="Template Name *"
                v-model="templateName"
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="col-sm-6">
              <v-text-field
                id="tpl_disp_name"
                label="Template Display Name *"
                v-model="templateDispName"
                hide-details="auto"
              ></v-text-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <v-select
                id="sect_id"
                label="Select Sections *"
                v-model="sectionsSelected"
                :options="sections"
                :items="sections"
                @change="createSelectedSectList"
                multiple
                chips
              ></v-select>
            </div>
            <div class="col-sm-6">
              <v-container fluid>
                <v-textarea
                  id="field_name"
                  label="Enter custom fields name separated by comma"
                  v-model="customFieldsSelected"
                  rows="1"
                ></v-textarea>
              </v-container>
            </div>
          </div>
          <div class="row float-right">
            <div class="col-sm-12">
              <v-btn color="primary" type="submit">Continue</v-btn>
            </div>
          </div>
        </b-form>
      </v-stepper-content>

      <v-stepper-content step="2">
        <b-form
          @submit.prevent="onSubmitTplQuesSectMap"
          @reset.prevent="resetForm"
          v-if="show"
          id="form-stage-2-id"
        >
          <div>
            <v-data-table
              v-model="selected"
              :headers="tplQuesSectHeaders"
              :items="tplQuesSectData"
              item-key="tpl_ques_id"
              show-select
              :search="search"
              :single-select="singleSelect"
              class="elevation-1"
              style="font-family: Poppins, Helvetica: sans-serif"
              :loading="loading"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <b-dropdown
                    text="Map Section"
                    class="m-md-2"
                    variant="primary"
                    id="dropdown-dropright"
                    dropright
                    :disabled="selected.length == 0"
                  >
                    <b-dropdown-item
                      v-for="sectionId in sectionsSelected"
                      :key="sectionId"
                      @click="updateTplQuesSectMap(sectionId)"
                      >{{ getSectionNameById(sectionId) }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-dialog v-model="dialog" max-width="75%">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Question Details</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Template No</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.tpl_id
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >CC Question Number</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.cc_ques_no
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Questionnaire</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.questionnaire
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Topic</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.topic
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >CC Integrated Reference</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.cc_mapping
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Question</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.ques_text
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Excerpt</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.excerpt
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Evidence Expires in</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.expiry_date
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Critical Question</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.critical_ques
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Regulations</v-col
                            >
                            <v-col cols="12" sm="9">
                              <ul class="list-unstyled p-0 m-0">
                                <li
                                  v-for="regulation in editedItem.regulations"
                                  v-bind:key="regulation"
                                >
                                  {{ regulation }}
                                </li>
                              </ul>
                            </v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Platforms</v-col
                            >
                            <v-col cols="12" sm="9">
                              <ul class="list-unstyled p-0 m-0">
                                <li
                                  v-for="platform in editedItem.platforms"
                                  v-bind:key="platform"
                                >
                                  {{ platform }}
                                </li>
                              </ul>
                            </v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Environments</v-col
                            >
                            <v-col cols="12" sm="9">
                              <ul class="list-unstyled p-0 m-0">
                                <li
                                  v-for="environment in editedItem.environments"
                                  v-bind:key="environment"
                                >
                                  {{ environment }}
                                </li>
                              </ul>
                            </v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Tags</v-col
                            >
                            <v-col cols="12" sm="9">
                              <ul class="list-unstyled p-0 m-0">
                                <li
                                  v-for="tag in editedItem.tags"
                                  v-bind:key="tag"
                                >
                                  {{ tag }}
                                </li>
                              </ul>
                            </v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Question Attachment Text</v-col
                            >
                            <v-col cols="12" sm="9">{{
                              editedItem.help_text
                            }}</v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Control Numbers</v-col
                            >
                            <v-col cols="12" sm="9">
                              <p
                                v-for="ctrl in editedItem.controls"
                                v-bind:key="ctrl.regln_name"
                              >
                                <span class="font-weight-bold"
                                  >{{ ctrl.regln_name }} :</span
                                >
                                &nbsp; {{ ctrl.ctrl_no }}
                              </p>
                            </v-col>
                            <v-col cols="12" sm="3" class="font-weight-bold"
                              >Field Data</v-col
                            >
                            <v-col cols="12" sm="9">
                              <ul class="list-unstyled p-0 m-0">
                                <li
                                  v-for="field_detail in editedItem.field_details"
                                  v-bind:key="field_detail"
                                >
                                  {{ field_detail }}
                                </li>
                              </ul>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close"
                          >Close</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-b-tooltip.hover.bottom="'View Question Details'"
                  small
                  class="mr-2"
                  @click="viewTplQues(item)"
                >
                  info
                </v-icon>
                <v-icon
                  v-b-tooltip.hover.bottom="'Delete Question'"
                  small
                  @click="deleteTplQues(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data></template>
            </v-data-table>
          </div>
          <div class="row">
            <div class="col-sm-12"><br /></div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <v-btn color="primary" @click="e1 = 1">Back</v-btn>
            </div>
            <div class="col-sm-8"></div>
            <div class="col-sm-2 d-flex flex-row-reverse">
              <v-btn
                color="primary"
                @click="e1 = 3"
                :disabled="
                  tplQuesSectData == undefined || tplQuesSectData.length <= 0
                "
                >Continue</v-btn
              >
            </div>
          </div>
        </b-form>
      </v-stepper-content>

      <v-stepper-content step="3">
        <b-form v-if="show" id="form-stage-3-id">
          <div>
            <v-data-table
              :headers="editTplQuesHeaders"
              :items="tplQuesSectData"
              :search="search"
              class="elevation-1"
              style="font-family: Poppins, Helvetica: sans-serif"
              :loading="loading"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Edit Question Details</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-dialog
                    v-model="dialogTplQuesEdit"
                    persistent
                    scrollable
                    max-width="600px"
                  >
                    <v-card>
                      <v-tabs
                        background-color="white"
                        color="deep-purple accent-4"
                        left
                      >
                        <v-tab href="#add_cf">Add Custom Field Details</v-tab>
                        <v-tab-item value="add_cf">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-card-text>
                                  <v-container>
                                    <v-row
                                      v-for="row in reqCustomFields"
                                      v-bind:key="row.custom_field_id"
                                    >
                                      <v-col cols="12">
                                        <v-textarea
                                          v-model="
                                            responses[
                                              `tqe_cf_${row.custom_field_id}`
                                            ]
                                          "
                                          :label="row.field_name"
                                          rows="1"
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="dialogTplQuesEdit = false"
                                    >Close</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="updateTplQues"
                                    >Save</v-btn
                                  >
                                </v-card-actions>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-tab-item>
                      </v-tabs>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-b-tooltip.hover.bottom="'View Question Details'"
                  small
                  class="mr-2"
                  @click="viewTplQues(item)"
                  >info</v-icon
                >
                <v-icon
                  v-if="customFieldsSelected.length > 0"
                  v-b-tooltip.hover.bottom="'Add Custom Field'"
                  small
                  class="mr-2"
                  @click="editTplQues(item)"
                  >mdi-plus</v-icon
                >
                <v-icon
                  v-b-tooltip.hover.bottom="'Edit Question'"
                  small
                  class="mr-2"
                  @click="editTplQuesData(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  v-b-tooltip.hover.bottom="'Delete Question'"
                  small
                  @click="deleteTplQues(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <template v-slot:no-data></template>
            </v-data-table>
          </div>
          <div class="row">
            <div class="col-sm-12"><br /></div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <v-btn color="primary" @click="e1 = 2">Back</v-btn>
            </div>
            <div class="col-sm-8"></div>
            <div class="col-sm-2 d-flex flex-row-reverse">
              <v-btn
                color="primary"
                @click="publishTemplate"
                :disabled="
                  tplQuesSectData == undefined || tplQuesSectData.length <= 0
                "
                >Publish</v-btn
              >
            </div>
          </div>
        </b-form>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import CCApiService from "@/core/services/aws.ccapi.service";

export default {
  data() {
    return {
      tpl_id: this.$route.params.id,
      e1: 1,
      show: true,
      errors: [],
      regulationsSelected: [],
      regulations: [],
      sectionsSelected: [],
      sections: [],
      step: "",
      templateDispName: "",
      templateName: "",
      questionnaireSelected: null,
      questionnaires: [],
      customFieldsSelected: "",
      msgModaleTitle: "",
      msg: "",
      dialog: false,
      dialogTplQuesEdit: false,
      search: "",
      singleSelect: false,
      selected: [],
      editTplQuesHeaders: [],
      tplQuesSectHeaders: [],
      tplQuesData: [],
      tplQuesSectData: [],
      loading: false,
      reqCustomFields: [],
      responses: [],
      tpl_status: 0,
      editedItem: {
        tpl_id: 0,
        tpl_ques_id: 0,
        qm_ques_id: 0,
        cc_ques_no: "",
        cc_mapping: "",
        topic: "",
        section: "",
        ques_text: "",
        excerpt: "",
        expiry_date: "",
        critical_ques: 0,
        help_text: "",
        tag: "",
        regulation: "",
        platform: "",
        environment: "",
        controls: "",
        field_detail: "",
        questionnaire: ""
      }
    };
  },
  components: {},
  beforeMount() {
    this.tpl_id = localStorage.getItem("tpl_id");
    this.getQuestionnaire();
    this.getSections();

    if (this.tpl_id > 0) {
      this.getTemplates();
      this.getTemplateQuestionList();
    }
  },
  created() {
    localStorage.setItem("tpl_ques_id", 0);
    this.initialize();
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.dialog = false;
      this.dialogTplQuesEdit = false;
      this.$nextTick(() => {});
    },
    initialize() {
      this.tplQuesSectHeaders = [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "tpl_ques_id",
          width: "5%"
        },
        { text: "CC Question Number", value: "cc_ques_no", width: "10%" },
        { text: "CC Integrated Reference", value: "cc_mapping", width: "10%" },
        { text: "Topic", value: "topic", width: "10%" },
        { text: "Question", value: "ques_text", width: "45%" },
        { text: "Section", value: "section", width: "10%" },
        { text: "Actions", value: "actions", sortable: false, width: "10%" }
      ];
      this.editTplQuesHeaders = [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "tpl_ques_id",
          width: "5%"
        },
        { text: "CC Question Number", value: "cc_ques_no", width: "10%" },
        { text: "CC Integrated Reference", value: "cc_mapping", width: "10%" },
        { text: "Topic", value: "topic", width: "10%" },
        { text: "Question", value: "ques_text", width: "45%" },
        { text: "Section", value: "section", width: "10%" },
        { text: "Actions", value: "actions", sortable: false, width: "10%" }
      ];
      this.getTemplateQuestionList();
    },
    onSubmitTemplate: function() {
      if (
        this.regulationsSelected.length <= 0 ||
        this.sectionsSelected.length <= "" ||
        this.questionnaireSelected.length <= 0 ||
        this.templateDispName == "" ||
        this.templateName == ""
      ) {
        this.showMsg("error", "Please fill in the fields marked as asterisk.");
        return;
      }

      if (this.tpl_id > 0) {
        this.patchTemplate();
      } else {
        this.postTemplate();
      }
    },
    onSubmitTplQuesSectMap(evt) {
      evt.preventDefault();
      //this.updateTplQuesSectMap();
    },
    onSubmitUpdateTplQues(evt) {
      evt.preventDefault();
      this.updateTplQues();
    },
    resetForm() {
      this.regulationsSelected = [];
      this.questionnaires = [];
      this.templateDispName = "";
      this.templateName = "";
      this.sectionsSelected = [];
      this.customFieldsSelected = "";
      this.questionnaireSelected = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    postTemplate: function() {
      const apiName = "OneAudit";
      const path = "/templates";

      const params = {
        step: 1,
        tpl_name: this.templateName,
        tpl_disp_name: this.templateDispName,
        questionnaire_id: this.questionnaireSelected,
        regulations: this.regulationsSelected,
        sections: this.sectionsSelected,
        custom_fields: String(this.customFieldsSelected)
      };

      CCApiService.post(apiName, path, params)
        .then(response => {
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") &&
                response.data.hasOwnProperty("data_updated") == true &&
                response.data.data_updated == "true" &&
                response.data.hasOwnProperty("id") == true &&
                response.data.id > 0
              ) {
                this.tpl_id = response.data.id;
                localStorage.setItem("tpl_id", this.tpl_id);
                this.e1 = 2;
                this.getTemplateQuestionList();
                //this.showMsg("success", "Template successfully added");
              } else {
                this.showMsg("error", "Failed to add template");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          if (
            error.hasOwnProperty("response") == true &&
            error.response != undefined
          ) {
            if (
              error.response.hasOwnProperty("data") == true &&
              error.response.data != undefined
            ) {
              if (
                error.response.data.hasOwnProperty("message") == true &&
                error.response.data.message != undefined
              ) {
                this.showMsg("error", error.response.data.message);
              } else {
                this.showMsg("error", error.response.data);
              }
            } else {
              this.showMsg("error", error.response);
            }
          } else {
            this.showMsg("error", error);
          }
        });
    },
    patchTemplate: function() {
      const apiName = "OneAudit";
      const path = "/templates/" + this.tpl_id;

      const params = {
        //step: this.e1,
        step: 1,
        tpl_name: this.templateName,
        tpl_disp_name: this.templateDispName,
        questionnaire_id: this.questionnaireSelected,
        regulations: this.regulationsSelected,
        sections: this.sectionsSelected,
        custom_fields: String(this.customFieldsSelected)
      };

      CCApiService.patch(apiName, path, params)
        .then(response => {
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") &&
                response.data.hasOwnProperty("data_updated") == true &&
                response.data.data_updated == "true" &&
                response.data.hasOwnProperty("id") == true &&
                response.data.id > 0
              ) {
                this.tpl_id = response.data.id;
                this.e1 = 2;
                this.getTemplateQuestionList();
                //this.showMsg("success", "Template successfully updated");
              } else {
                this.showMsg("error", "Failed to update templates");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          if (
            error.hasOwnProperty("response") == true &&
            error.response != undefined
          ) {
            if (
              error.response.hasOwnProperty("data") == true &&
              error.response.data != undefined
            ) {
              if (
                error.response.data.hasOwnProperty("message") == true &&
                error.response.data.message != undefined
              ) {
                this.showMsg("error", error.response.data.message);
              } else {
                this.showMsg("error", error.response.data);
              }
            } else {
              this.showMsg("error", error.response);
            }
          } else {
            this.showMsg("error", error);
          }
        });
    },
    updateTplQuesSectMap: function(sectionId) {
      const apiName = "OneAudit";
      const path = "/templates/" + this.tpl_id;
      let tplQuesIdList = [];
      for (let i in this.selected) {
        tplQuesIdList.push(this.selected[i].tpl_ques_id);
      }
      const params = {
        //step: this.e1,
        step: 2,
        sect_id: sectionId,
        ques_ids: tplQuesIdList
      };

      if (this.tpl_id > 0) {
        CCApiService.patch(apiName, path, params)
          .then(response => {
            if (response != undefined && response.hasOwnProperty("error")) {
              if (response.error == "false") {
                if (
                  response.hasOwnProperty("data") &&
                  response.data.hasOwnProperty("data_updated") == true &&
                  response.data.data_updated == "true" &&
                  response.data.hasOwnProperty("id") == true &&
                  response.data.id > 0
                ) {
                  this.selected = [];
                  this.showMsg("success", "Sections successfully added");
                  this.getTemplateQuestionList();
                  //}
                } else {
                  this.showMsg(
                    "error",
                    "Failed to update sections with questions"
                  );
                }
              } else if (
                response.error == "true" &&
                response.hasOwnProperty("error_code") &&
                response.error_code != undefined
              ) {
                var message =
                  response.hasOwnProperty("data") && response.data != undefined
                    ? ", data: " + response.data.toString()
                    : "";
                this.showMsg(
                  "error",
                  "Failed. Error code: " + response.error_code + message
                );
              } else {
                this.showMsg("error", "An unexpected error occurred.");
              }
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          })
          .catch(error => {
            if (
              error.hasOwnProperty("response") == true &&
              error.response != undefined
            ) {
              if (
                error.response.hasOwnProperty("data") == true &&
                error.response.data != undefined
              ) {
                if (
                  error.response.data.hasOwnProperty("message") == true &&
                  error.response.data.message != undefined
                ) {
                  this.showMsg("error", error.response.data.message);
                } else {
                  this.showMsg("error", error.response.data);
                }
              } else {
                this.showMsg("error", error.response);
              }
            } else {
              this.showMsg("error", error);
            }
          });
      }
    },
    updateTplQues: function() {
      const apiName = "OneAudit";
      const path = "/templates/" + this.tpl_id;

      let reqCustomFieldDetails = [];
      for (var key of Object.keys(this.responses)) {
        let reqCfid = key.replace("tqe_cf_", "");
        reqCustomFieldDetails.push({
          custom_field_id: reqCfid,
          field_text: this.responses[key]
        });
      }

      const params = {
        step: 3,
        action: 1,
        id: parseInt(this.tpl_id),
        tpl_ques_custom_fields: [
          {
            tpl_ques_id: this.tpl_ques_id,
            custom_field_details: reqCustomFieldDetails
          }
        ]
      };

      if (this.tpl_id > 0) {
        CCApiService.patch(apiName, path, params)
          .then(response => {
            if (response != undefined && response.hasOwnProperty("error")) {
              if (response.error == "false") {
                if (
                  response.hasOwnProperty("data") &&
                  response.data.hasOwnProperty("data_updated") == true &&
                  response.data.data_updated == "true" &&
                  response.data.hasOwnProperty("id") == true &&
                  response.data.id > 0
                ) {
                  this.tpl_id = response.data.id;
                  this.dialogTplQuesEdit = false;
                  this.responses = [];
                } else {
                  this.showMsg("error", "Failed to add custom field details");
                }
              } else if (
                response.error == "true" &&
                response.hasOwnProperty("error_code") &&
                response.error_code != undefined
              ) {
                var message =
                  response.hasOwnProperty("data") && response.data != undefined
                    ? ", data: " + response.data.toString()
                    : "";
                this.showMsg(
                  "error",
                  "Failed. Error code: " + response.error_code + message
                );
              } else {
                this.showMsg("error", "An unexpected error occurred.");
              }
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          })
          .catch(error => {
            if (
              error.hasOwnProperty("response") == true &&
              error.response != undefined
            ) {
              if (
                error.response.hasOwnProperty("data") == true &&
                error.response.data != undefined
              ) {
                if (
                  error.response.data.hasOwnProperty("message") == true &&
                  error.response.data.message != undefined
                ) {
                  this.showMsg("error", error.response.data.message);
                } else {
                  this.showMsg("error", error.response.data);
                }
              } else {
                this.showMsg("error", error.response);
              }
            } else {
              this.showMsg("error", error);
            }
          });
      }
    },

    publishTemplate: function() {
      const apiName = "OneAudit";
      const path = "/templates/" + this.tpl_id;

      const params = {
        step: 3,
        action: 2,
        id: parseInt(this.tpl_id)
        //tpl_ques_custom_fields: []
      };

      if (this.tpl_id > 0) {
        CCApiService.patch(apiName, path, params)
          .then(response => {
            if (response != undefined && response.hasOwnProperty("error")) {
              if (response.error == "false") {
                if (
                  response.hasOwnProperty("data") &&
                  response.data.hasOwnProperty("data_updated") == true &&
                  response.data.data_updated == "true" &&
                  response.data.hasOwnProperty("id") == true &&
                  response.data.id > 0
                ) {
                  this.tpl_status = 1;
                  this.showMsg("success", "Template successfully published");
                } else {
                  this.showMsg("error", "Failed to publish template");
                }
              } else if (
                response.error == "true" &&
                response.hasOwnProperty("error_code") &&
                response.error_code != undefined
              ) {
                var message =
                  response.hasOwnProperty("data") && response.data != undefined
                    ? ", data: " + response.data.toString()
                    : "";
                this.showMsg(
                  "error",
                  "Failed. Error code: " + response.error_code + message
                );
              } else {
                this.showMsg("error", "An unexpected error occurred.");
              }
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          })
          .catch(error => {
            if (
              error.hasOwnProperty("response") == true &&
              error.response != undefined
            ) {
              if (
                error.response.hasOwnProperty("data") == true &&
                error.response.data != undefined
              ) {
                if (
                  error.response.data.hasOwnProperty("message") == true &&
                  error.response.data.message != undefined
                ) {
                  this.showMsg("error", error.response.data.message);
                } else {
                  this.showMsg("error", error.response.data);
                }
              } else {
                this.showMsg("error", error.response);
              }
            } else {
              this.showMsg("error", error);
            }
          });
      }
    },

    getQuestionnaire() {
      const apiName = "OneAudit";
      const path = "/questionnaire";
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                for (var i = 0; i < response.length; i++) {
                  this.questionnaires.push({
                    text: response[i].questionnaire_name,
                    value: response[i].questionnaire_id
                  });
                }
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        })
        .finally(() => {
          this.getRegulations();
        });
    },

    getRegulations() {
      if (this.questionnaireSelected == null || this.questionnaireSelected <= 0)
        return false;
      const apiName = "OneAudit";
      const path =
        "/regulations?questionnaire_id=" + this.questionnaireSelected;
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                this.regulations = [];
                for (var i = 0; i < response.length; i++) {
                  this.regulations.push({
                    text: response[i].regln_name,
                    value: response[i].regln_id
                  });
                }
                this.regulations.sort((a, b) => (a.text > b.text ? 1 : -1));
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    getRegulationNameById: function(id) {
      for (var i = 0; i < this.editedItem.length; i++) {
        if (this.regulations[i].value == id) {
          return this.regulations[i].text;
        }
      }
    },
    getSections() {
      const apiName = "OneAudit";
      const path = "/sections";
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                for (var i = 0; i < response.length; i++) {
                  this.sections.push({
                    text: response[i].sect_name,
                    value: response[i].sect_id
                  });
                }
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    getSectionNameById: function(id) {
      for (var i = 0; i < this.sections.length; i++) {
        if (this.sections[i].value == id) {
          return this.sections[i].text;
        }
      }
    },
    getTemplates() {
      const apiName = "OneAudit";
      const path = this.tpl_id > 0 ? "/templates/" + this.tpl_id : "/templates";
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                for (var i = 0; i < response.length; i++) {
                  this.templateName =
                    response[i].tpl_name != undefined
                      ? response[i].tpl_name
                      : "";
                  this.templateDispName =
                    response[i].tpl_disp_name != undefined
                      ? response[i].tpl_disp_name
                      : "";
                  this.templateVersion =
                    response[i].tpl_version != undefined
                      ? response[i].tpl_version
                      : null;
                  this.questionnaireSelected =
                    response[i].questionnaire.id != undefined
                      ? response[i].questionnaire.id
                      : null;
                  this.publishStatus =
                    response[i].publish_status != undefined
                      ? response[i].publish_status
                      : null;
                  if (response[i].regulations != undefined) {
                    for (var j = 0; j < response[i].regulations.length; j++) {
                      this.regulationsSelected.push(
                        response[i].regulations[j].regln_id
                      );
                    }
                  }
                  if (response[i].sections != undefined) {
                    this.sectionsSelected = [];
                    for (var k = 0; k < response[i].sections.length; k++) {
                      this.sectionsSelected.push(
                        response[i].sections[k].sect_id
                      );
                    }
                  }
                  if (response[i].custom_fields != undefined) {
                    let strCustomFields = [];
                    for (var l = 0; l < response[i].custom_fields.length; l++) {
                      strCustomFields.push(
                        response[i].custom_fields[l].field_name
                      );
                    }
                    if (strCustomFields.length > 0) {
                      this.customFieldsSelected = strCustomFields.toString();
                    }
                  }
                }
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    createSelectedRegList: function() {
      if (this.regulationsSelected.length > 0) {
        this.controlRegulation = [{ text: "Please Select", value: null }];
        for (var i = 0; i < this.regulationsSelected.length; i++) {
          this.controlRegulation.push({
            text: this.getRegulationNameById(this.regulationsSelected[i]),
            value: this.regulationsSelected[i]
          });
        }
      }
    },
    showMsg: function(type, msg) {
      this.msgModaleTitle = type == "error" ? "Error" : "Message";
      this.msg = msg;
      this.$refs["msg-modal"].show();
    },

    getTemplateQuestionList: function() {
      const apiName = "OneAudit";
      const path = "/templates/" + this.tpl_id + "/questions";
      this.loading = true;
      CCApiService.get(apiName, path)
        .then(response => {
          this.tplQuesSectData = [];
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (response.hasOwnProperty("data") && response.data.length > 0) {
                response = response.data;
                for (var index in response) {
                  var environmentNames = [];
                  var regulationNames = [];
                  var platformNames = [];
                  var tagNames = [];
                  var fieldDetails = [];
                  var controlNames = [];

                  if (response[index].environments != undefined) {
                    for (var i in response[index].environments) {
                      environmentNames.push(
                        response[index].environments[i].env_name
                      );
                    }
                  }

                  if (response[index].regulations != undefined) {
                    for (var j in response[index].regulations) {
                      regulationNames.push(
                        response[index].regulations[j].regln_name
                      );

                      for (var x in response[index].controls) {
                        if (x == response[index].regulations[j].regln_id) {
                          controlNames.push({
                            regln_name:
                              response[index].regulations[j].regln_name,
                            ctrl_no: response[index].controls[x].toString()
                          });
                        }
                      }
                    }
                  }

                  if (response[index].platforms != undefined) {
                    for (var k in response[index].platforms) {
                      platformNames.push(
                        response[index].platforms[k].plat_name
                      );
                    }
                  }

                  if (response[index].tags != undefined) {
                    for (var t in response[index].tags) {
                      tagNames.push(response[index].tags[t].tag_name);
                    }
                  }

                  if (response[index].field_details != undefined) {
                    for (var u in response[index].field_details) {
                      fieldDetails.push(
                        response[index].field_details[u].field_text
                      );
                    }
                  }

                  if (response[index].tpl_ques_id == undefined) {
                    break;
                  }

                  this.tplQuesSectData.push({
                    tpl_ques_id: response[index].tpl_ques_id,
                    tpl_id: response[index].tpl_id,
                    qm_ques_id: response[index].qm_ques_id,
                    cc_mapping: response[index].cc_mapping,
                    cc_ques_no: response[index].cc_ques_no,
                    topic: response[index].topic.name,
                    section: response[index].section.name,
                    ques_text: response[index].ques_text,
                    excerpt: response[index].excerpt,
                    expiry_date: response[index].expiry_date + " days",
                    critical_ques:
                      response[index].critical_ques == 1 ? "Yes" : "No",
                    help_text: response[index].help_text,
                    regulations: regulationNames,
                    platforms: platformNames,
                    environments: environmentNames,
                    questionnaire: response[index].questionnaire.name,
                    tags: tagNames,
                    field_details: fieldDetails,
                    controls: controlNames
                  });
                }
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getTemplateQuestion: function() {
      const apiName = "OneAudit";
      const path =
        "/templates/" + this.tpl_id + "/questions/" + this.tpl_ques_id;
      this.loading = true;
      CCApiService.get(apiName, path)
        .then(response => {
          this.tplQuesSectData = [];
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (response.hasOwnProperty("data") && response.data.length > 0) {
                response = response.data;
                for (var index in response) {
                  var environmentNames = [];
                  var regulationNames = [];
                  var platformNames = [];
                  var tagNames = [];
                  var fieldDetails = [];
                  var controlNames = [];

                  if (response[index].environments != undefined) {
                    for (var i in response[index].environments) {
                      environmentNames.push(
                        response[index].environments[i].env_name
                      );
                    }
                  }

                  if (response[index].regulations != undefined) {
                    for (var j in response[index].regulations) {
                      regulationNames.push(
                        response[index].regulations[j].regln_name
                      );

                      for (var x in response[index].controls) {
                        if (x == response[index].regulations[j].regln_id) {
                          controlNames.push({
                            regln_name:
                              response[index].regulations[j].regln_name,
                            ctrl_no: response[index].controls[x].toString()
                          });
                        }
                      }
                    }
                  }

                  if (response[index].platforms != undefined) {
                    for (var k in response[index].platforms) {
                      platformNames.push(
                        response[index].platforms[k].plat_name
                      );
                    }
                  }

                  if (response[index].tags != undefined) {
                    for (var t in response[index].tags) {
                      tagNames.push(response[index].tags[t].tag_name);
                    }
                  }

                  if (response[index].field_details != undefined) {
                    for (var u in response[index].field_details) {
                      fieldDetails.push(
                        response[index].field_details[u].field_text
                      );
                    }
                  }

                  if (response[index].tpl_ques_id == undefined) {
                    break;
                  }

                  this.tplQuesSectData.push({
                    tpl_ques_id: response[index].tpl_ques_id,
                    tpl_id: response[index].tpl_id,
                    qm_ques_id: response[index].qm_ques_id,
                    cc_mapping: response[index].cc_mapping,
                    cc_ques_no: response[index].cc_ques_no,
                    topic: response[index].topic.name,
                    section: response[index].section.name,
                    ques_text: response[index].ques_text,
                    excerpt: response[index].excerpt,
                    expiry_date: response[index].expiry_date + " days",
                    critical_ques:
                      response[index].critical_ques == 1 ? "Yes" : "No",
                    help_text: response[index].help_text,
                    regulations: regulationNames,
                    platforms: platformNames,
                    environments: environmentNames,
                    tags: tagNames,
                    field_details: fieldDetails,
                    controls: controlNames
                  });
                }
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editTplQues(item) {
      var tpl_ques_id =
        item != undefined &&
        item.hasOwnProperty("tpl_ques_id") &&
        item.tpl_ques_id > 0
          ? item.tpl_ques_id
          : 0;

      localStorage.setItem("tpl_ques_id", tpl_ques_id);
      this.tpl_ques_id = tpl_ques_id;
      this.getCustomFields();
      this.dialogTplQuesEdit = true;
    },

    editTplQuesData(item) {
      var tpl_ques_id =
        item != undefined &&
        item.hasOwnProperty("tpl_ques_id") &&
        item.tpl_ques_id > 0
          ? item.tpl_ques_id
          : 0;

      localStorage.setItem("tpl_ques_id", tpl_ques_id);
      this.tpl_ques_id = tpl_ques_id;
      //this.dialogTplQuesEdit = true;
      //this.$router.resolve({ name: "questionnaire-template-question-edit", window.open(href, '_blank') });
      let routeData = this.$router.resolve({
        name: "questionnaire-template-question-edit"
      });
      window.open(routeData.href, "_blank");
    },

    viewTplQues(item) {
      if (item == undefined || item.tpl_ques_id == undefined) {
        return;
      }

      const apiName = "OneAudit";
      const path =
        "/templates/" + this.tpl_id + "/questions/" + item.tpl_ques_id;

      CCApiService.get(apiName, path)
        .then(response => {
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (response.hasOwnProperty("data")) {
                response = response.data;
                this.editedItem = Object.assign({}, item);
                //localStorage.setItem("tpl_ques_id", item.tpl_ques_id);
                this.getTemplateQuestionList();
                this.dialog = true;
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    deleteTplQues(item) {
      const id =
        item != undefined &&
        item.hasOwnProperty("tpl_ques_id") &&
        item.tpl_ques_id > 0
          ? item.tpl_ques_id
          : 0;
      if (id > 0 && confirm("Are you sure you want to delete this item?")) {
        const apiName = "OneAudit";
        const path = "/templates/" + this.tpl_id + "/questions/" + id;
        CCApiService.delete(apiName, path)
          .then(() => {
            this.initialize();
            this.showMsg("success", "Record successfully deleted");
          })
          .catch(error => {
            this.showMsg("error", error);
          });
      }
    },
    getCustomFields: async function() {
      const apiName = "OneAudit";
      const path = this.tpl_id > 0 ? "/templates/" + this.tpl_id : "/templates";
      await CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
                this.reqCustomFields = [];
                for (var i = 0; i < response.length; i++) {
                  if (response[i].custom_fields != undefined) {
                    for (var l = 0; l < response[i].custom_fields.length; l++) {
                      this.reqCustomFields.push({
                        custom_field_id:
                          response[i].custom_fields[l].custom_field_id,
                        field_name: response[i].custom_fields[l].field_name,
                        field_type: response[i].custom_fields[l].field_type,
                        is_mandatory: response[i].custom_fields[l].is_mandatory,
                        min_length: response[i].custom_fields[l].min_length,
                        max_length: response[i].custom_fields[l].max_length
                      });
                    }
                  }
                }
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },
    getRegulationControls() {
      const apiName = "OneAudit";
      const path = this.tpl_id > 0 ? "/templates/" + this.tpl_id : "/questions";
      CCApiService.get(apiName, path)
        .then(response => {
          if (
            response != undefined &&
            response.hasOwnProperty("error") == true
          ) {
            if (response.error == "false") {
              if (
                response.hasOwnProperty("data") == true &&
                response.data.length > 0
              ) {
                response = response.data;
              } else {
                this.showMsg("error", "Record(s) not found");
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") == true &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    },

    nextClickTemplateList: function() {
      if (this.tpl_status == 1) {
        this.tpl_id = 0;
        this.$router.push({ name: "questionnaire-templates-list" });
      }
    }
  }
};
</script>
